exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-svg-js": () => import("./../../../src/pages/svg.js" /* webpackChunkName: "component---src-pages-svg-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-about-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/about.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-about-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-index-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/index.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-neural-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/neural.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-neural-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-neural-reduced-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/neural_reduced.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-neural-reduced-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-peer-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/peer.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-peer-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-sketches-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/sketches.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-sketches-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-worlds-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/abbozza/worlds.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-abbozza-worlds-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-about-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/about.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-about-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-algoviz-algoviz-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/algoviz/algoviz.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-algoviz-algoviz-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-algoviz-local-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/algoviz/local.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-algoviz-local-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-cars-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/cars.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-cars-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-gradient-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/gradient.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-gradient-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-index-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/index.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-numbers-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/numbers.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-numbers-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-policing-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/policing.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-policing-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-robot-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/robot.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-robot-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-simplenets-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/simplenets.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-simplenets-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-sumory-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/sumory.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-sumory-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-talk-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/talk.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-talk-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-turingtable-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/MIK/turingtable.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-mik-turingtable-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-ml-unplugged-index-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/ml_unplugged/index.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-ml-unplugged-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-clusters-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/clusters.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-clusters-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-concentric-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/concentric.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-concentric-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-crossing-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/crossing.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-crossing-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-deep-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/deep.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-deep-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-index-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/index.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-inhalt-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/inhalt.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-inhalt-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-linear-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/linear.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-linear-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-network-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/network.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-network-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-2-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/neuron2.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-2-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-3-d-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/neuron_3d.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-3-d-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-learn-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/neuron_learn.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-learn-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/neuron.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-neuron-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-screws-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/ki/networks/screws.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-ki-networks-screws-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-stellen-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/stellen.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-stellen-mdx" */),
  "component---src-templates-page-jsx-content-file-path-static-content-pages-theses-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/home/michael/Didaktik/homepage/page/static/content/pages/theses.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-static-content-pages-theses-mdx" */)
}

